import React from 'react'
import { animated } from 'react-spring'
import styled from '@emotion/styled'

import { breakpoints } from '../styles/variables'
import { useTextEffect } from '../hooks/useTextEffect'

const StyledHomeHeroLeadEn = styled.div`
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: var(--color-ground-1000);
  font-family: var(--font-family-serif);
  font-size: 1.2rem;
  letter-spacing: 0.025em;
  line-height: 1.75;
  position: relative;
  text-align: right;
  white-space: nowrap;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 1.4rem;
  }

  @media (prefers-color-scheme: dark) {
    color: inherit;
  }
`

const Lead = styled.div`
  bottom: 2rem;
  position: absolute;
  right: 2rem;
`

export const HomeHeroLeadEn: React.FC = () => {
  const list = useTextEffect(
    'In the deep forest,\nwhere the fresh water springs:\nthis is where our liquor is made.\nOsuzuyama Distillery'
  )

  return (
    <StyledHomeHeroLeadEn>
      <Lead>
        {list.map((props, i) => {
          if (props && props.char !== ' ') {
            return (
              // @ts-ignore
              <animated.span key={i.toString()} style={props.style}>
                {props.char}
              </animated.span>
            )
          }
          if (props) {
            return <span key={i.toString()}>{props.char}</span>
          }
          return <br key={i.toString()} />
        })}
      </Lead>
    </StyledHomeHeroLeadEn>
  )
}
